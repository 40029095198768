// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".B6R4hW1cSGh6_qxfAOKF{\n    border-radius: 69% 31% 77% 23% / 26% 37% 63% 74%;\n    width: 400px;\n    height: 400px;\n    box-shadow:  26px 26px 47px #00416f31,\n    -26px -26px 47px #005d9f2a;\n  }\n.NbgPc3tJY0ccRmXcuDIh{\n    color: #004E87;\n}", "",{"version":3,"sources":["webpack://./client/src/components/Hero.module.css"],"names":[],"mappings":"AAAA;IACI,gDAAgD;IAChD,YAAY;IACZ,aAAa;IACb;8BAC0B;EAC5B;AACF;IACI,cAAc;AAClB","sourcesContent":[".heroImages{\n    border-radius: 69% 31% 77% 23% / 26% 37% 63% 74%;\n    width: 400px;\n    height: 400px;\n    box-shadow:  26px 26px 47px #00416f31,\n    -26px -26px 47px #005d9f2a;\n  }\n.titleMain{\n    color: #004E87;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heroImages": "B6R4hW1cSGh6_qxfAOKF",
	"titleMain": "NbgPc3tJY0ccRmXcuDIh"
};
export default ___CSS_LOADER_EXPORT___;
