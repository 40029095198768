import Hero from "../../components/Hero";

export function Home() {
	return (
		<main role="main">
            <Hero />
		</main>
	);
}

export default Home;
