// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".g_Y2bwrm97Fk1ewdd_oA{\n    margin-top: 10px;\n    margin-left: 20%;\n    width: 60%;\n}\n.tQAMY0DzDoz8AFQv4FN_{\n    padding-left: 10px!important;\n    padding-right: 10px!important;\n    background-color: red;\n}\n@media screen and (max-width: 768px) {\n.YyvBJDOKBzIWgTTXJY74{\n    flex-direction: column!important;\n    align-content: space-around;\n    align-items: center;\n    flex-wrap: wrap;\n}\n.cgHGLXZVz_gSSzJkvq1C{\nwidth: 100%;\nmin-height: 100vh;\n}\n  }", "",{"version":3,"sources":["webpack://./client/src/pages/result/Result.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,UAAU;AACd;AACA;IACI,4BAA4B;IAC5B,6BAA6B;IAC7B,qBAAqB;AACzB;AACA;AACA;IACI,gCAAgC;IAChC,2BAA2B;IAC3B,mBAAmB;IACnB,eAAe;AACnB;AACA;AACA,WAAW;AACX,iBAAiB;AACjB;EACE","sourcesContent":[".selectGroup{\n    margin-top: 10px;\n    margin-left: 20%;\n    width: 60%;\n}\n.btn{\n    padding-left: 10px!important;\n    padding-right: 10px!important;\n    background-color: red;\n}\n@media screen and (max-width: 768px) {\n.page{\n    flex-direction: column!important;\n    align-content: space-around;\n    align-items: center;\n    flex-wrap: wrap;\n}\n.dataTable{\nwidth: 100%;\nmin-height: 100vh;\n}\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectGroup": "g_Y2bwrm97Fk1ewdd_oA",
	"btn": "tQAMY0DzDoz8AFQv4FN_",
	"page": "YyvBJDOKBzIWgTTXJY74",
	"dataTable": "cgHGLXZVz_gSSzJkvq1C"
};
export default ___CSS_LOADER_EXPORT___;
