// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bFN6IdMfga_H0GFzGOqS {\n  width: 80%;\n  margin: 0 auto;\n}\n", "",{"version":3,"sources":["webpack://./client/src/pages/result/ResultMobV.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,cAAc;AAChB","sourcesContent":[".form {\n  width: 80%;\n  margin: 0 auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "bFN6IdMfga_H0GFzGOqS"
};
export default ___CSS_LOADER_EXPORT___;
