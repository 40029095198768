// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jHIE1jxFUvV2soJukETQ {\n    width: 60%;\n    margin: 0 auto;\n    min-height: 100vh;\n  }\n  \n  .CV1trAzx2UI25JRv9qD4 {\n    display: flex;\n    justify-content: space-between;\n    flex-direction: column;\n    flex-wrap: nowrap;\n    align-content: space-around;\n    align-items: center;\n    text-align: center;\n    margin-top: 1em;\n  }\n  \n  .CrUj7aT1doQhoYg1hNwu {\n    text-align: center;\n    margin-top: 20px;\n }\n  .cEB0Ia_eJOUXKCxV3BUg{\n    color:red;\n  }\n  @media screen and (max-width: 768px) {\n    .jHIE1jxFUvV2soJukETQ {\n      width: 100%;\n    }\n    \n    .CV1trAzx2UI25JRv9qD4 {\n      display: flex;\n      flex-direction: column;\n      justify-content: center;\n      text-align: center;\n    }\n  }", "",{"version":3,"sources":["webpack://./client/src/pages/user-preferences/UserPreferences.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,cAAc;IACd,iBAAiB;EACnB;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,sBAAsB;IACtB,iBAAiB;IACjB,2BAA2B;IAC3B,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,kBAAkB;IAClB,gBAAgB;CACnB;EACC;IACE,SAAS;EACX;EACA;IACE;MACE,WAAW;IACb;;IAEA;MACE,aAAa;MACb,sBAAsB;MACtB,uBAAuB;MACvB,kBAAkB;IACpB;EACF","sourcesContent":[".form {\n    width: 60%;\n    margin: 0 auto;\n    min-height: 100vh;\n  }\n  \n  .formGroup {\n    display: flex;\n    justify-content: space-between;\n    flex-direction: column;\n    flex-wrap: nowrap;\n    align-content: space-around;\n    align-items: center;\n    text-align: center;\n    margin-top: 1em;\n  }\n  \n  .containerBtn {\n    text-align: center;\n    margin-top: 20px;\n }\n  .error-message{\n    color:red;\n  }\n  @media screen and (max-width: 768px) {\n    .form {\n      width: 100%;\n    }\n    \n    .formGroup {\n      display: flex;\n      flex-direction: column;\n      justify-content: center;\n      text-align: center;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "jHIE1jxFUvV2soJukETQ",
	"formGroup": "CV1trAzx2UI25JRv9qD4",
	"containerBtn": "CrUj7aT1doQhoYg1hNwu",
	"error-message": "cEB0Ia_eJOUXKCxV3BUg"
};
export default ___CSS_LOADER_EXPORT___;
