// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".b8YPwG0VKUWFBxJLW7Rc {\n  width: 60%;\n  margin: 0 auto;\n}\n\n.itq87NwPD9ZTCD1TNgZQ {\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 25px;\n}\n\n@media screen and (max-width: 768px) {\n  .b8YPwG0VKUWFBxJLW7Rc {\n    width: 95%;\n    margin: 10px;\n  }\n  .itq87NwPD9ZTCD1TNgZQ {\n    margin-bottom: 15px;\n  }\n  .tmkc4rPP3hEiADmWAJdH {\n    width: 100%;\n  }\n}", "",{"version":3,"sources":["webpack://./client/src/components/UserForm.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE;IACE,UAAU;IACV,YAAY;EACd;EACA;IACE,mBAAmB;EACrB;EACA;IACE,WAAW;EACb;AACF","sourcesContent":[".form {\n  width: 60%;\n  margin: 0 auto;\n}\n\n.formGroup {\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 25px;\n}\n\n@media screen and (max-width: 768px) {\n  .form {\n    width: 95%;\n    margin: 10px;\n  }\n  .formGroup {\n    margin-bottom: 15px;\n  }\n  .w-50 {\n    width: 100%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "b8YPwG0VKUWFBxJLW7Rc",
	"formGroup": "itq87NwPD9ZTCD1TNgZQ",
	"w-50": "tmkc4rPP3hEiADmWAJdH"
};
export default ___CSS_LOADER_EXPORT___;
