// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#WkBza7vajGnCK492Jh7m{\n    background-color: #FA4F3D;\n    color: white;\n    position: relative;\n    width: 100%;\n    margin-top: 2em;     \n}\n\n", "",{"version":3,"sources":["webpack://./client/src/components/Footer.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,WAAW;IACX,eAAe;AACnB","sourcesContent":["#site-footer{\n    background-color: #FA4F3D;\n    color: white;\n    position: relative;\n    width: 100%;\n    margin-top: 2em;     \n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"site-footer": "WkBza7vajGnCK492Jh7m"
};
export default ___CSS_LOADER_EXPORT___;
