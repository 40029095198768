const data = {
   services: [
     "Legal Aid",
     "Drug De-Addiction",
     "Education",
     "Employment & Life Skills",
     "Education for children",
     "Health Care",
     "Mental Health",
     "Shelter, Food and Clothing Assistance",
     "Important Documents",
   ],
   location: [
      "North",
      "South",
      "Central",
      "West",
      "East",
    ],
};
export default data;